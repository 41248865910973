// import { getFormFields } from "gatsby-theme-myaccount/src/core/components/MyAccount/forms/utils"

const getFormFields = path => {
  const defaultData = require(`../Forms/defaults/${path}`)
  // try {
  //     let projectData = require(`../Forms/${MYACCOUNT_THEME}/${path}`)
  //     if (projectData)
  //         return projectData;
  // } catch (err) {}

  return defaultData;
}

const fileName = 'notificationPreferences.json';

export default getFormFields(fileName)
